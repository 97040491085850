import { useEffect, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import Link from "next/link";
import useProvider from "../lib/provider";
import useSWR from "swr";
import { fetcherWithLocale } from "../lib/useRequest";
import useStore from "../store/store";
import SwiperCore, { Navigation, Pagination, Autoplay, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import AppLayout from "../components/layouts/AppLayout";
import LogoArrow from "../components/icons/Arrow.svg";

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

export async function getStaticProps({ locale }) {
  const home = await fetcherWithLocale(useProvider.HOME_URL, locale);
  const homeSection = await fetcherWithLocale(
    useProvider.HOME_SECTIONS_URL,
    locale
  );

  const news = await fetcherWithLocale(
    `${process.env.NEXT_PUBLIC_BASE_URL}/api/resources?resourcePageName=news-and-articles&page=1&itemsPerPage=3`,
    locale
  );
  return {
    props: {
      home,
      news,
      homeSection
    },
    revalidate: true,
  };
}

export default function Home(props) {
  const router = useRouter();
  const { locale } = router;
  const [isShowIndex, setIsShowIndex] = useState(0);
  const [isShowContent, setIsShowContent] = useState(null);
  const [showNews, setShowNews] = useState([]);
  const useLocale = useStore(state => state.locale);

  const handleGoTo = section => {
    var url = "";

    if (section === "Investor Toolkit") return (url = "/investor-toolkit");
    if (section === "Production Model" || section === "Model Produksi")
      return (url = "/preparation/pre-operational");
    if (section === "Financial Modeling" || section === "Model Finansial")
      return (url = "/financial-modeling");
    if (section === "Resources Page" || section === "Informasi Lainnya")
      return (url = "/resources/cattle-stakeholders");
  };

  // console.log(locale);
  const { data: home } = useSWR(
    [useProvider.HOME_URL, locale],
    fetcherWithLocale,
    {
      initialData: props.home
    }
  );

  const { data: homeSection } = useSWR(
    [useProvider.HOME_SECTIONS_URL, locale],
    fetcherWithLocale,
    {
      initialData: props.homeSection
    }
  );

  const { data: news } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_BASE_URL}/api/resources?resourcePageName=news-and-articles&page=1&itemsPerPage=3`,
      locale
    ],
    fetcherWithLocale,
    { initialData: props.news }
  );

  useEffect(() => {
    if (setIsShowIndex) {
      setIsShowContent(news[isShowIndex]);
    }
  }, [isShowIndex]);

  useEffect(() => {
    if (news.length > 3) {
      let temp = news.slice(0, 3);
      setShowNews(temp);
    } else {
      setShowNews(news);
    }
  }, [news]);

  return (
    <AppLayout
      title={useLocale.title.default}
      meta_description={useLocale.meta.description.default}
    >
      <header className="relative flex flex-col overflow-hidden md:h-screen items-between">
        <Image
          className="absolute inset-0 z-0 object-cover object-bottom w-full h-full"
          src="/assets/img/gapensiska-background.jpeg"
          layout='fill'
          alt="Header image"
          placeholder="blur"
          priority
        />
        <span className="absolute inset-0 w-full h-full bg-gradient-to-b from-transparent to-brand-blue-dark"></span>
        <div className="relative z-0 flex flex-col justify-center w-full h-full max-w-6xl px-4 mx-auto 2xl:max-w-7xl">
          <div className="max-w-3xl pt-40 mx-auto text-center md:pt-16">
            <h1 className="text-3xl font-bold md:text-6xl">
              <span className="text-white">{home.translations.title}</span>
            </h1>
            <p className="mt-5 text-base md:mt-8 md:text-lg text-white/80">
              {home.translations.sub_title}
            </p>
            <Link href="/investor-toolkit">
              <a className="inline-flex items-center px-5 py-2.5 mt-5 md:mt-8 space-x-2 font-medium transition-colors duration-300 rounded-md shadow-md md:px-6 md:py-3 text-brand-blue-dark bg-brand-blue-lightest hover:bg-brand-green-dark hover:text-white">
                <span>{useLocale.goToInvestorToolkit}</span>
                <LogoArrow className="w-5 h-5 transition-transform duration-300 ease-in-out group-hover:translate-x-1 mt-0.5 lg:w-6 lg:h-6" />
              </a>
            </Link>
          </div>
        </div>
        <div className="relative max-w-6xl px-4 mx-auto text-white 2xl:max-w-7xl">
          {isShowContent && (
            <div className="py-4 mt-12 border-t border-b md:items-center md:mt-0 md:space-x-6 md:py-8 md:flex border-white/10">
              <Swiper
                className="w-full overflow-hidden md:w-44"
                autoplay={{ delay: 7000, disableOnInteraction: false }}
                onSlideChange={swiper => setIsShowIndex(swiper.activeIndex)}
              >
                {showNews.map(item => (
                  <SwiperSlide className="!w-full" key={item.id}>
                    <div className="aspect-w-16 aspect-h-9">
                      <Image
                        className="object-cover rounded-md"
                        src={item.thumbnail}
                        alt="photo"
                        layout='fill'
                        placeholder="blur"
                        priority
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="items-center md:flex md:flex-1 md:space-x-8">
                <div className="flex-1">
                  <h2 className="mb-2 md:mb-1.5 text-lg leading-snug font-semibold mt-3 md:mt-0">
                    {isShowContent.translation.title}
                  </h2>
                  <p className="text-sm text-white/70">
                    {isShowContent.translation.excerpt}
                  </p>
                </div>
                <div className="mt-4 md:pl-8 md:ml-8 md:border-l md:mt-0 border-white/10">
                  <a
                    href={isShowContent.link}
                    target="_blank"
                    className="inline-block px-5 py-2.5 text-sm font-medium rounded-md bg-white/10"
                  >
                    {useLocale.readMore}
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </header>
      {homeSection.map(section =>
        section.id === 1 ? (
          <div
            key={section.id}
            className="py-12 text-white bg-gradient-to-b from-brand-blue-dark to-brand-green-dark lg:py-20"
          >
            <div className="max-w-6xl px-4 mx-auto 2xl:max-w-7xl">
              <p className="mb-5 text-2xl font-semibold text-center lg:text-xl lg:mb-8">
                {section.translation.name}
              </p>
              <div className="space-y-16 lg:space-y-24">
                {section.sections.map((res, index, arr) => (
                  <div
                    id={index}
                    key={index}
                    className={`
                  ${index % 2 === 0 && "lg:flex-row-reverse"}
                  lg:flex relative`}
                  >
                    <div
                      className={`
                      ${index % 2 === 0 ? "lg:ml-20" : "lg:mr-20"}
                      lg:w-1/2 mb-5 lg:mb-0 z-10`}
                    >
                      <div className="overflow-hidden lg:mt-2">
                        <Image 
                          src={res.image.url}
                          alt={res.translation.name}
                          className="w-full"
                          width="584"
                          height="425"
                          loading="lazy"
                         />
                      </div>
                    </div>
                    <div className="z-10 lg:w-1/2">
                      <h2 className="inline-block mb-3 text-2xl font-semibold lg:text-4xl lg:mb-4">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-brand-blue-lightest to-brand-neon-lightest">
                          {res.translation.name}
                        </span>
                      </h2>
                      <p className="mb-2 text-sm leading-relaxed lg:text-base lg:leading-relaxed">
                        {res.translation.description}
                      </p>
                      <p className="mb-4 text-sm font-light leading-relaxed opacity-70 lg:text-base lg:leading-relaxed lg:mb-6">
                        {res.translation.data_gathering}
                      </p>
                      <Link href={handleGoTo(res.translation.name)}>
                        <a className="inline-flex items-center px-5 py-2.5 text-sm font-medium duration-200 bg-white rounded-md bg-opacity-5 text-brand-neon-lightest lg:text-sm hover:bg-opacity-10">
                          {useLocale.goTo2 + " " + res.translation.name}
                          <LogoArrow className="w-5 h-5 ml-2 lg:w-6 lg:h-6" />
                        </a>
                      </Link>
                    </div>
                    <div className="absolute inset-x-0 inset-y-0 z-0 hidden -mb-20 text-black lg:block">
                      <div className="w-4 h-4 mx-auto mt-3 bg-white border-2 border-white rounded-full bg-opacity-30"></div>
                      <div
                        className={`
                        ${
                          arr.length - 1 === index
                            ? "bg-gradient-to-b from-white to-transparent"
                            : "bg-white "
                        }
                        mx-auto w-0.5 h-full opacity-10
                        `}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div key={section.id} className="py-12 lg:py-20">
            <div className="max-w-6xl px-4 mx-auto 2xl:max-w-7xl">
              <p className="mb-5 text-2xl font-semibold text-center text-gray-400 lg:text-xl lg:mb-8">
                {section.translation.name}
              </p>
              <div className="space-y-16 lg:space-y-24">
                {section.sections.map((res, index, arr) => (
                  <div
                    id={res.id}
                    key={res.id}
                    className={`
                  ${index % 2 === 0 && "lg:flex-row-reverse"}
                  lg:flex items-center relative`}
                  >
                    <div
                      className={`
                  ${index % 2 === 0 ? "lg:ml-20" : "lg:mr-20"}
                  lg:w-1/2 mb-5 lg:mb-0 z-10`}
                    >
                      <div className="overflow-hidden">
                        <Image
                          src={res.image.url}
                          alt={res.translation.name}
                          className="w-full"
                          width="584"
                          height="425"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="z-10 lg:w-1/2">
                      <h2 className="inline-block mb-3 text-2xl font-semibold lg:text-4xl lg:mb-4">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-brand-blue-dark to-brand-blue-lighter">
                          {res.translation.name}
                        </span>
                      </h2>
                      <p className="mb-2 text-sm font-semibold leading-relaxed text-gray-700 lg:text-base lg:leading-relaxed">
                        {res.translation.description}
                      </p>
                      <p className="mb-4 text-sm leading-relaxed opacity-70 lg:text-base lg:leading-relaxed lg:mb-6">
                        {res.translation.data_gathering}
                      </p>
                      <Link href={handleGoTo(res.translation.name)}>
                        <a className="inline-flex items-center px-2 py-2 text-xs font-medium tracking-wide duration-200 rounded-md bg-brand-blue-lighter bg-opacity-5 text-brand-blue-lighter lg:text-sm hover:bg-opacity-10">
                          {useLocale.goTo2 + " " + res.translation.name}
                          <LogoArrow className="w-5 h-5 ml-2 lg:w-6 lg:h-6" />
                        </a>
                      </Link>
                    </div>
                    {/* <div className="absolute inset-x-0 inset-y-0 z-0 hidden -mb-20 text-black lg:block">
                      <div className="w-4 h-4 mx-auto mt-3 border-2 rounded-full border-brand-blue-lighter bg-brand-blue-lightest bg-opacity-30 opacity-40"></div>
                      <div
                        className={`
                        ${
                          arr.length - 1 === index
                            ? "bg-gradient-to-b from-brand-blue-lighter to-transparent"
                            : "bg-brand-blue-lighter"
                        }
                        mx-auto w-0.5 h-full opacity-10
                        `}
                      />
                    </div> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </AppLayout>
  );
}
